export const DummyData = [

    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"",
    },
    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"2401:4900:1cdf:f38e:9d59:da9a:3e31:6ada",
    },
    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"2401:4900:1cdf:f38e:9d59:da9a:3e31:6ada",
    },
    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"2401:4900:1cdf:f38e:9d59:da9a:3e31:6ada",
    },
    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"2401:4900:1cdf:f38e:9d59:da9a:3e31:6ada",
    },
    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"2401:4900:1cdf:f38e:9d59:da9a:3e31:6ada",
    },
    {
        "Admin Name": "Anudeep",
        "Role": "Accountant",
        "Session start time": "Nov 16, 2022 7:09:36 AM",
        "Session Finish time": "Nov 16, 2022 7:09:36 AM",
        "Session Device": "QWE Laptop",
        "Session IP":"2401:4900:1cdf:f38e:9d59:da9a:3e31:6ada",
    },
]