import { CircularProgress } from '@mui/material'
import React from 'react'

export default function StyledLoader() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="-2.24%" stopColor="#ed5dcd" />
            <stop offset="100%" stopColor="rgba(95, 93, 215, 0.71)" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </React.Fragment>
  )
}
