import React from 'react'
import DownloadReport from '../components/reports/DownloadReport'

export default function Reports() {
  return (
    <div>
        
        <DownloadReport/>
    </div>
  )
}
