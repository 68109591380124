import React from "react";
import RemoteSession from "../components/cpoSupport/startRemoteSession/RemoteSession";
export default function StartRemoteSession() {

const handleSubmit = ()=>{
}

  return (
    <>
      <RemoteSession handleSubmit={handleSubmit} />
    </>
  );
}
